<template>
  <v-card elevation="1">
    <v-form ref="form" v-model="valid" lazy-validation class="pt-4">
      <v-row class="px-6 pt-2">
        <v-col cols="6">
          <v-text-field
            v-model="formData.titulo"
            placeholder="Título do modelo"
            outlined
            dense
            :clearable="true"
            label="Título do modelo"
            required
            :rules="rules.genericRules"
            :disabled="viewOnly"
          >
          </v-text-field>
          <v-combobox
            v-model="formData.tags"
            :items="listTags"
            hide-no-data
            hide-selected
            item-text="nome"
            item-value="id"
            label="Tags"
            placeholder="Escolha as tags"
            outlined
            dense
            multiple
            small-chips
            chips
            :clearable="true"
            required
            :rules="rules.multiSelectRules"
            deletable-chips
            :return-object="true"
            :disabled="viewOnly"
          ></v-combobox>
          <!-- <v-autocomplete
            v-model="formData.artigos"
            :items="listArtigos"
            hide-no-data
            hide-selected
            item-text="titulo"
            item-value="id"
            label="Pesquisa de Artigos"
            placeholder="Escolha os artigos"
            outlined
            dense
            multiple
            small-chips
            chips
            :clearable="true"
            required
            :rules="rules.multiSelectRules"
            deletable-chips
            :return-object="true"
            :disabled="viewOnly"
          ></v-autocomplete> -->
          <v-autocomplete
            v-model="formData.produtos"
            :items="listProdutos"
            hide-no-data
            hide-selected
            item-text="nome"
            item-value="id"
            label="Pesquisa de Produtos"
            placeholder="Escolha as produtos"
            outlined
            dense
            multiple
            small-chips
            chips
            :clearable="true"
            deletable-chips
            :return-object="true"
            :disabled="viewOnly"
          ></v-autocomplete>
        </v-col>
        <v-col cols="6">
          <v-autocomplete
            v-model="formData.assunto"
            :items="listAssuntos"
            hide-no-data
            hide-selected
            item-text="text"
            item-value="value"
            placeholder="Selecione o tema"
            outlined
            dense
            :clearable="true"
            label="Tema"
            required
            :rules="rules.selectRules"
            :return-object="true"
            :disabled="viewOnly"
          ></v-autocomplete>
          <v-combobox
            v-model="formData.categorias"
            :items="listCategorias"
            hide-no-data
            hide-selected
            item-text="nome"
            item-value="id"
            label="Categorias"
            placeholder="Escolha as categorias"
            outlined
            dense
            multiple
            small-chips
            chips
            :clearable="true"
            required
            :rules="rules.multiSelectRules"
            deletable-chips
            :return-object="true"
            :disabled="viewOnly"
          ></v-combobox>
          <v-btn
            class="mb-6 buscar-artigo"
            depressed
            color="fmq_gray"
            dark
            block
            @click="buscarArtigo"
            :disabled="viewOnly"
          >
            <v-icon small> mdi-magnify </v-icon>Buscar Artigos</v-btn
          >
        </v-col>
        <v-col cols="12" class="pt-0">
          <v-simple-table class="mb-8 tabela-artigo" v-if="artigos.length > 0">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Titulo</th>
                  <th class="text-left">Autor</th>
                  <th class="text-left">Ano de publicação</th>
                  <th class="text-left">Ações</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in artigos" :key="item.id">
                  <td>{{ item.titulo }}</td>
                  <td>{{ item.autor }}</td>
                  <td>{{ item.dataPublicacao }}</td>
                  <td>
                    <v-btn
                      small
                      class="mr-2"
                      depressed
                      color="fmq_gray"
                      outlined
                      dark
                      @click="openPDF(item.hash)"
                    >
                      PDF
                    </v-btn>
                    <v-btn
                      small
                      depressed
                      color="red"
                      outlined
                      dark
                      @click="excluirArtigo(item)"
                      :disabled="viewOnly"
                    >
                      Excluir
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-textarea
            outlined
            label="Resumo para o representante"
            v-model="formData.descricao"
            :clearable="true"
            rows="3"
            :disabled="viewOnly"
            placeholder="Explicação para uso do modelo"
          ></v-textarea>
          <p class="caption mt-6 mb-1">Conteúdo</p>
          <editor
            :disabled="viewOnly"
            v-model="formData.conteudo"
            :init="{
              promotion: false,
              language: 'pt_BR',
              height: 1200,
              menubar: true,
              plugins: [
                'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'print', 'preview', 'anchor',
                'searchreplace', 'visualblocks', 'code', 'fullscreen',
                'insertdatetime', 'media', 'table', 'paste', 'code', 'help', 'wordcount',
              ],
              selector: '#editor',
              menu: {
                custom: {
                  title: 'FQM',
                  items: 'crm nome especialidade data assinatura',
                },
              },
              menubar: 'file edit view insert format tools table custom',
              setup: function (editor) {
                editor.ui.registry.addMenuItem('crm', {
                  text: 'CRM',
                  onAction: function () {
                    menuItems('CRM');
                    editor.insertContent('[CRM]');
                  },
                });
                editor.ui.registry.addMenuItem('nome', {
                  text: 'Nome',
                  onAction: function () {
                    menuItems('Nome');
                    editor.insertContent('[Nome]');
                  },
                });
                editor.ui.registry.addMenuItem('especialidade', {
                  text: 'Especialidade',
                  onAction: function () {
                    menuItems('Espec');
                    editor.insertContent('[Especialidade]');
                  },
                });
                editor.ui.registry.addMenuItem('data', {
                  text: 'Data',
                  onAction: function () {
                    menuItems('Data');
                    editor.insertContent('[Data]');
                  },
                });
                editor.ui.registry.addMenuItem('assinatura', {
                  text: 'Assinatura',
                  onAction: function () {
                    menuItems('Assinatura');
                    editor.insertContent('[Assinatura]');
                    //const logoUrl = 'https://pfarma.com.br/images/empresa/fqm-farmoquimica.jpg'
                    //const nome = $store.getters.getName
                    //editor.insertContent(`<b>` + nome + `</b>` + `<p>`)
                    //const style = 'width=70px; height=70px'
                    //editor.insertContent(`<img src=${logoUrl} style=${style}>`)
                    //editor.insertContent(`<p>`)
                  },
                });
              },
              toolbar:
                'undo redo | formatselect | bold italic backcolor | \
                             alignleft aligncenter alignright alignjustify | \
                             bullist numlist outdent indent | removeformat | help',
            }"
        /></v-col>
      </v-row>
    </v-form>
    <v-card-actions class="px-6 pb-4 mt-4">
      <v-btn
        v-if="!viewOnly"
        depressed
        color="fmq_gray"
        dark
        @click="send"
        :loading="loadingBtn"
        >{{ isEdit ? "Editar" : "Criar" }}</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import Editor from "@tinymce/tinymce-vue";
import { rules } from "@/utils/rules.js";
export default {
  name: "ModelosFormulario",
  components: {
    editor: Editor,
  },
  props: {
    loadingBtn: {
      type: Boolean,
      default: false,
    },
    modelo: {
      type: Object,
      default: () => {},
    },
    listTags: {
      type: Array,
      default: () => [],
    },
    listCategorias: {
      type: Array,
      default: () => [],
    },
    listArtigos: {
      type: Array,
      default: () => [],
    },
    listProdutos: {
      type: Array,
      default: () => [],
    },
    artigos: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    formData: {
      titulo: null,
      assunto: null,
      conteudo: null,
      categorias: [],
      tags: [],
      artigos: [],
      produtos: [],
      descricao: [],
      mesasId: 2,
    },
    rules: rules,
    valid: true,
    isEdit: false,
    viewOnly: false,
    listAssuntos: [
      { text: "Anatomia/Fisiologia", value: "Anatomia/Fisiologia" },
      { text: "Patologia", value: "Patologia" },
      { text: "Produto", value: "Produto" },
      { text: "Concorrência", value: "Concorrência" },
      { text: "BVC", value: "BVC" },
      { text: "P&A", value: "P&A" },
      { text: "Material promocional", value: "Material promocional" },
      { text: "Outros", value: "Outros" },
    ],
  }),
  created() {
    if (this.$route.name === "BancoDeCartasTREditar") {
      this.formData = this.modelo;
      this.isEdit = true;
    }
    if (this.$route.name === "BancoDeCartasTRVisualizar") {
      this.formData = this.modelo;
      this.viewOnly = true;
    }
  },
  methods: {
    validate() {
      this.$refs.form.validate();
    },
    async send() {
      const data = await this.formatPayload();
      if (this.formValid) this.$emit("send", data);
    },
    async sendAndView() {
      const data = await this.formatPayload();
      if (this.formValid) this.$emit("sendAndView", data);
    },
    menuItems(item) {
      return item;
    },
    formatPayload() {
      const payload = {};
      payload.titulo = this.formData.titulo;
      payload.assunto = this.formData.assunto.value;
      payload.conteudo = this.formData.conteudo;
      // payload.categorias = this.formData.categorias.map((item) => item.id);
      // payload.tags = this.formData.tags.map((item) => item.id);
      // payload.artigos = this.formData.artigos.map((item) => item.id);
      payload.produtos = this.formData.produtos.map((item) => item.id);
      payload.descricao = this.formData.descricao;
      payload.mesasd = this.formData.mesasId;

      const categorias = [];
      this.formData.categorias.forEach((categoria) => {
        if (categoria.id) {
          categorias.push(categoria.id);
        } else {
          categorias.push(categoria);
        }
      });

      payload.categorias = categorias;

      const tags = [];
      this.formData.tags.forEach((tag) => {
        if (tag.id) {
          tags.push(tag.id);
        } else {
          tags.push(tag);
        }
      });

      payload.tags = tags;

      const artigos = [];
      this.artigos.forEach((artigo) => {
        if (artigo.id) {
          artigos.push(artigo.id);
        } else {
          artigos.push(artigo);
        }
      });

      payload.artigos = artigos;
      return payload;
    },
    buscarArtigo() {
      this.$emit("buscarArtigo");
    },
    excluirArtigo(item) {
      this.$emit("excluirArtigo", item);
    },
    openPDF(hash) {
      window.open(
        process.env.VUE_APP_API + "donwload/artigos/" + hash,
        "_blank"
      );
    },
  },
  computed: {
    formValid() {
      return this.$refs.form.validate();
    },
  },
};
</script>

<style scoped>
.buscar-artigo {
  height: 40px !important;
}
.tabela-artigo {
  border: thin solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}
</style>
